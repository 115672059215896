<template>
  <div class="text-center">
    <v-dialog
      v-model="openDialog"
      width="500"
      height="500"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>Description Form </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="fisherydesc.animaldesc"
                label="Description"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="sel.length"
            small
            color="info"
            outlined
            rounded
            text
            @click="updatefishery"
          >
            <v-icon>mdi-content-save</v-icon> Update
          </v-btn>
          <v-spacer />

          <v-btn
            v-if="!sel.length"
            small
            outlined
            rounded
            text
            color="success"
            @click="saveNewfishery"
          >
            <v-icon>mdi-content-save</v-icon>
            Save as New Record
          </v-btn>
          <v-btn
            v-if="sel.length"
            small
            outlined
            rounded
            text
            color="error"
            @click="deletefishery"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            text
            @click="closeDialog"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {

    props: ['openDialog', 'sel'],
    data: () => ({
      dialog: false,
      fisherydesc: {
        animaldesc: '',
        animalclass_id: '1',
      },
    }),

    watch: {
      sel: function (val) {
        if (val.length) {
          this.fisherydesc = val[0]
        } else {
          return val
        }
      },
    },

    mounted () {
    // this.fisherydesc.animaldesc=''
    },

    methods: {
      closeDialog () {
        this.$emit('closeDlg', false)
      },
      saveNewfishery () {
        this.$store.dispatch('fisherydesc/postData', this.fisherydesc)
        this.$toast.success('Save Complete')
        this.closeDialog()
      },
      async deletefishery () {
        const res = await confirm('Delete Data')
        if (res) {
          this.$store.dispatch('fisherydesc/deleteData', this.fisherydesc.id)
          this.$toast.success('Delete Complete')
        } else {
          this.$toast.info('Delete Cancelled')
        }
        this.closeDialog()
      },
      async updatefishery () {
        await this.$store.dispatch('fisherydesc/updateData', this.fisherydesc)
        this.$toast.success('Update Complete')
        this.closeDialog()
      },
    },
  }
</script>
