<template>
  <div>
    <v-row>
      <v-col>
        <fisherydesc />
      </v-col>
      <v-col>
        <fisheryvariety />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import fisherydesc from './fisherydesc.vue'
  import fisheryvariety from './fisheryvariety.vue'

  export default {
    components: {
      fisherydesc,
      fisheryvariety,
    },
  }
</script>
